import { Spot } from 'shared-lib';

interface OfficeDiscount {
  discount: number;
  isFree: boolean;
}

export class PriceCalculationService {
  static calculateTotalPrice(
    startSlot: Spot,
    endSlot: Spot | null,
    availableSlots: Spot[],
    dayPrice: number | null,
    minimumSlots: number,
    singleSlotPrice: number,
    multipleSlotPrice: number,
    officeDiscount?: OfficeDiscount
  ): number {
    if (!endSlot) return 0;
    
    const startTime = new Date(`2000/01/01 ${startSlot.start}`);
    const endTime = new Date(`2000/01/01 ${endSlot.start}`);
    
    // Считаем количество слотов
    const selectedSlots = availableSlots.filter(slot => {
      const slotTime = new Date(`2000/01/01 ${slot.start}`);
      return slotTime >= startTime && slotTime <= endTime;
    });

    const numSlots = selectedSlots.length;

    // Рассчитываем цену двумя способами
    const singleSlotTotal = numSlots * singleSlotPrice; // Цена по одиночному тарифу
    const multipleSlotTotal = minimumSlots * multipleSlotPrice; // Цена за минимальное количество слотов по множественному тарифу

    // Берем меньшую цену
    let totalPrice = Math.min(singleSlotTotal, multipleSlotTotal);

    // Если количество слотов >= минимального, считаем по множественному тарифу
    if (numSlots >= minimumSlots) {
      totalPrice = numSlots * multipleSlotPrice;
    }
    
    // Применяем ограничение дневной ценой, если она установлена
    if (dayPrice) {
      totalPrice = Math.min(totalPrice, dayPrice);
    }

    // Применяем скидку резидента, если она есть
    if (officeDiscount) {
      if (officeDiscount.isFree) {
        return 0;
      }
      totalPrice = totalPrice * (1 - officeDiscount.discount / 100);
    }
    
    return totalPrice;
  }

  static calculateTotalPriceForSlots(
    slots: Spot[],
    dayPrice: number | null,
    minimumSlots: number,
    basePrice: number,           // базовая цена за слот (бывший single_slot_price)
    multipleSlotPrice: number    // цена за слот при множественном бронировании
  ): number {
    if (slots.length === 0) return 0;

    const numSlots = slots.length;

    // Рассчитываем цену двумя способами
    const singleSlotTotal = numSlots * basePrice;           // Цена по базовому тарифу
    const multipleSlotTotal = minimumSlots * multipleSlotPrice; // Цена за минимальное количество слотов по множественному тарифу

    // Берем меньшую цену
    let totalPrice = Math.min(singleSlotTotal, multipleSlotTotal);

    // Если количество слотов >= минимального, считаем по множественному тарифу
    if (numSlots >= minimumSlots) {
      totalPrice = numSlots * multipleSlotPrice;
    }

    // Применяем ограничение дневной ценой, если она установлена
    return dayPrice ? Math.min(totalPrice, dayPrice) : totalPrice;
  }
} 