import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { LocationService } from 'shared-lib';
import { useAuth } from 'shared-lib';
import { getDoc, doc } from 'firebase/firestore';
import { db } from 'shared-lib';

// Хук для получения публичных локаций
export const usePublicLocations = () => {
  return useQuery({
    queryKey: ['public-locations'],
    queryFn: async () => {
      const locationService = LocationService.getInstance();
      return await locationService.fetchPublicLocations();
    }
  });
};

// Хук для получения информации о локации по ID
export const useLocation = (locationId: string) => {
  return useQuery({
    queryKey: ['location', locationId],
    queryFn: async () => {
      const locationService = LocationService.getInstance();
      return await locationService.getLocationById(locationId);
    },
    enabled: !!locationId
  });
};

// Хук для получения офисов в локации
export const useOfficesInLocation = (locationId: string) => {
  return useQuery({
    queryKey: ['location-offices', locationId],
    queryFn: async () => {
      const locationService = LocationService.getInstance();
      return await locationService.fetchOfficesForLocation(locationId);
    },
    enabled: !!locationId
  });
};

// Хук для получения статуса пользователя в локации
export const useUserLocationStatus = (locationId: string) => {
  const { user } = useAuth();
  
  return useQuery({
    queryKey: ['user-location-status', locationId, user?.uid],
    queryFn: async () => {
      if (!user) return null;
      
      const locationService = LocationService.getInstance();
      return await locationService.getUserStatusInLocation(locationId, user.uid);
    },
    enabled: !!user && !!locationId
  });
};

// Хук для получения запроса пользователя на резидентство в локации
export const useUserResidencyRequest = (locationId: string) => {
  const { user } = useAuth();
  
  return useQuery({
    queryKey: ['user-residency-request', locationId, user?.uid],
    queryFn: async () => {
      if (!user) return null;
      
      const locationService = LocationService.getInstance();
      return await locationService.getUserResidencyRequest(locationId, user.uid);
    },
    enabled: !!user && !!locationId
  });
};

// Хук для создания запроса на резидентство
export const useCreateResidencyRequest = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  
  return useMutation({
    mutationFn: async ({
      locationId,
      reason
    }: {
      locationId: string;
      reason: string;
    }) => {
      if (!user) throw new Error('User not authenticated');
      
      // Получаем данные пользователя из коллекции users
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      let firstName = '';
      let lastName = '';
      let displayName = user.displayName || '';
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        firstName = userData.firstName || '';
        lastName = userData.lastName || '';
        // Если есть имя и фамилия, формируем полное имя
        if (firstName && lastName) {
          displayName = `${firstName} ${lastName}`;
        }
      }
      
      const locationService = LocationService.getInstance();
      
      return await locationService.createResidencyRequest(
        locationId, 
        user.uid, 
        reason,
        { firstName, lastName, displayName }
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user-residency-request', variables.locationId] });
    }
  });
};

// Хук для получения локаций, где пользователь имеет роль резидента
export const useUserResidencyLocations = () => {
  const { user } = useAuth();
  
  return useQuery({
    queryKey: ['user-residency-locations', user?.uid],
    queryFn: async () => {
      if (!user) return [];
      
      const locationService = LocationService.getInstance();
      return await locationService.getLocationsByUserRole(user.uid, 'resident');
    },
    enabled: !!user
  });
}; 