import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PublicOffer: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Публичная оферта
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          1. Общие положения
        </Typography>
        <Typography paragraph>
          ИП Нетребский Леонид Сергеевич (ИНН: 631906096027) предлагает любому дееспособному физическому
          лицу заключить договор аренды помещений на условиях, изложенных в настоящей оферте.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          2. Порядок бронирования и оплаты
        </Typography>
        <Typography paragraph>
          2.1. Бронирование помещения осуществляется через сайт после авторизации пользователя.
        </Typography>
        <Typography paragraph>
          2.2. Оплата должна быть произведена в течение часа после бронирования. При отсутствии оплаты в указанный срок бронирование автоматически аннулируется.
        </Typography>
        <Typography paragraph>
          2.3. При регулярной аренде (повторяющиеся периоды):
        </Typography>
        <Typography component="ul">
          <li>Производится автоматическое списание средств. В случае невозможности автоматического списания арендатор обязан самостоятельно произвести оплату</li>
          <li>Оплата производится в течение часа после окончания предыдущей аренды</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          3. Правила отмены бронирования
        </Typography>
        <Typography paragraph>
          3.1. Отмена бронирования возможна не позднее чем за 24 часа до начала аренды.
        </Typography>
        <Typography paragraph>
          3.2. При отмене менее чем за 24 часа оплата не возвращается.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          4. Обязанности арендатора
        </Typography>
        <Typography paragraph>
          4.1. Арендатор обязуется:
        </Typography>
        <Typography component="ul">
          <li>Обеспечить сохранность имущества</li>
          <li>Не допускать на территорию посторонних лиц</li>
          <li>Произвести фотофиксацию помещения после окончания аренды</li>
          <li>Убедиться, что все приглашенные лица покинули помещение</li>
          <li>Проверить надежность закрытия всех дверей путем контрольной попытки открытия</li>
          <li>Привести помещение в исходное состояние и самостоятельно обеспечить вывоз и утилизацию мусора в соответствии с требованиями законодательства РФ</li>
          <li>Сообщить о завершении аренды через приложение или другие каналы связи</li>
          <li>Произвести фотофиксацию помещения при обнаружении любых повреждений или неисправностей до начала использования</li>
          <li>Нести полную материальную ответственность за любой ущерб, причиненный помещению или оборудованию во время аренды</li>
          <li>Не передавать коды доступа третьим лицам и нести ответственность за все действия, совершенные с использованием полученных кодов доступа</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          5. Разрешение споров
        </Typography>
        <Typography paragraph>
          5.1. Все споры разрешаются путем переговоров.
        </Typography>
        <Typography paragraph>
          5.2. При невозможности урегулирования спора путем переговоров, спор передается на рассмотрение
          в суд по месту нахождения помещения (г. Самара, ул. Ленинская, д. 43).
        </Typography>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
        Дата последнего обновления: 20.01.2025
      </Typography>
    </Container>
  );
};

export default PublicOffer; 