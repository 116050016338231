import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography, Box, Paper, Button, CircularProgress } from '@mui/material';
import { Booking, Office, BookingMainInfo, AccessCodes, PaymentInfo } from 'shared-lib';
import { useBooking, useOffice, useCancelBooking, useCompleteBooking, useStartBooking } from '../hooks/useBookingQueries';
import PaymentDrawer from './PaymentDrawer';
import BookingCheckInDrawer from './booking/BookingCheckInDrawer';
import { useTestPayment } from '../hooks/useTestPayment';
import { CancelBookingDrawer } from 'shared-lib';
import { useCancelBooking as useCustomCancelBooking } from 'shared-lib';
import { FullScreenDrawer } from 'shared-lib';
import { Location } from 'react-router-dom';

const formatTimeLeft = (targetDate: Date): string => {
  const now = new Date();
  const diffMs = targetDate.getTime() - now.getTime();
  const diffMinutes = Math.ceil(diffMs / (1000 * 60));
  
  if (diffMinutes <= 0) return '';
  
  if (diffMinutes > 24 * 60) {
    const days = Math.floor(diffMinutes / (24 * 60));
    return `через ${days} д.`;
  }
  
  if (diffMinutes >= 60) {
    const hours = Math.floor(diffMinutes / 60);
    return `через ${hours} ч.`;
  }
  
  return `через ${Math.max(1, diffMinutes)} мин.`;
};

const isStartDisabled = (booking: Booking, office: Office | null | undefined) => {
  if (!office) return true;
  
  const now = new Date();
  const startTime = new Date(booking.startTime);
  const pauseMinutes = office.duration?.pause || 0;
  
  return now.getTime() < startTime.getTime() - (pauseMinutes * 60 * 1000);
};

interface BookingDetailsProps {
  open: boolean;
  bookingId: string;
  onClose: () => void;
  location: Location;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ 
  open, 
  bookingId, 
  onClose,
  location 
}) => {
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState<'start' | 'completion' | null>(null);
  const startMutation = useStartBooking();
  const [, setUpdateTrigger] = useState(0);
  const [searchParams] = useSearchParams();
  const { testPayment } = useTestPayment();
  const [, setPaymentTimer] = useState(0);

  const { 
    data: booking,
    isLoading,
    error: bookingError
  } = useBooking(bookingId);

  const { 
    data: office,
  } = useOffice(booking?.officeId);

  const cancelMutation = useCancelBooking();
  const completeMutation = useCompleteBooking();

  const { 
    isOpen: isCancelDrawerOpen,
    openCancelDrawer,
    closeCancelDrawer,
    comment: cancelComment,
    setComment: setCancelComment,
    handleCancel,
    isLoading: isCancelling,
    error: cancelError
  } = useCustomCancelBooking((bookingId: string, comment: string) => 
    cancelMutation.mutateAsync({ bookingId, comment })
  );

  useEffect(() => {
    if (!booking || !isStartDisabled(booking, office || undefined)) return;
    
    const timer = setInterval(() => {
      setUpdateTrigger(prev => prev + 1);
    }, 60000);
    
    return () => clearInterval(timer);
  }, [booking, office]);

  useEffect(() => {
    const handleTestPayment = async () => {
      if (!booking?.payment?.paymentId) return;
      
      const payParam = searchParams.get('pay');
      if (payParam === null) return;

      try {
        await testPayment(booking.payment.paymentId, {
          isDeadlineExpired: payParam === 'expired',
          isRejected: payParam === 'rejected'
        });
      } catch (error) {
        console.error('Test payment error:', error);
      }
    };

    if (process.env.REACT_APP_ENV === 'development') {
      handleTestPayment();
    }
  }, [booking?.payment?.paymentId, searchParams, testPayment]);

  useEffect(() => {
    if (!booking?.createdAt || 
        !booking.payment || 
        ['CONFIRMED', 'AUTHORIZED', 'CANCELLED', 'REJECTED'].includes(booking.payment.status)) {
      return;
    }

    const deadline = new Date(new Date(booking.createdAt).getTime() + 60 * 60 * 1000);
    if (new Date() >= deadline) return;

    const timer = setInterval(() => {
      setPaymentTimer(prev => prev + 1);
    }, 60000); // Обновляем каждую минуту
    
    return () => clearInterval(timer);
  }, [booking?.createdAt, booking?.payment]);

  const handleComplete = async ({ photos, comment }: { photos: string[], comment: string }) => {
    if (!bookingId) return;
    
    await completeMutation.mutateAsync({
      bookingId,
      photos,
      comment
    });
  };

  const handleStart = async ({ photos, comment }: { photos: string[], comment: string }) => {
    if (!bookingId) return;
    
    await startMutation.mutateAsync({
      bookingId,
      photos,
      comment
    });
  };

  const canCancel = (booking: any) => {
    if (booking.status !== 'NEW') return false;
    const now = new Date();
    const startTime = new Date(booking.startTime);
    const hoursUntilStart = (startTime.getTime() - now.getTime()) / (1000 * 60 * 60);
    return hoursUntilStart >= 24;
  };

  const canStart = (booking: Booking) => {
    if (booking.status !== 'NEW') return false;
    if (!['CONFIRMED', 'AUTHORIZED'].includes(booking.payment?.status || '')) return false;
    const now = new Date();
    const endTime = new Date(booking.endTime);
    return now < endTime;
  };

  const getStartButtonTooltip = (booking: Booking | undefined) => {
    if (!booking || !office) return '';
    
    const now = new Date();
    const startTime = new Date(booking.startTime);
    const pauseMinutes = office.duration?.pause || 0;
    
    if (now.getTime() > booking.endTime.getTime()) {
      return 'Время бронирования истекло';
    }
    
    if (now.getTime() < startTime.getTime() - (pauseMinutes * 60 * 1000)) {
      return `Начать аренду можно не ранее чем за ${pauseMinutes} минут до начала бронирования`;
    }
    
    return '';
  };

  const canComplete = (booking: any) => {
    if (booking.status === 'IN_PROGRESS') return true;
    if (booking.status !== 'NEW') return false;
    const now = new Date();
    const endTime = new Date(booking.endTime);
    return now >= endTime;
  };

  const handleDrawerClose = () => {
    setDrawerType(null);
  };

  useEffect(() => {
    if (!open) {
      setPaymentDrawerOpen(false);
      setDrawerType(null);
    }
  }, [open]);

  if (!open) return null;

  if (isLoading) {
    return (
      <FullScreenDrawer open={open} onClose={onClose} title="Детали бронирования">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      </FullScreenDrawer>
    );
  }

  if (bookingError || !booking) {
    return (
      <FullScreenDrawer open={open} onClose={onClose} title="Ошибка">
        <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
          <Typography color="error">
            {bookingError instanceof Error ? bookingError.message : 'Бронирование не найдено'}
          </Typography>
        </Box>
      </FullScreenDrawer>
    );
  }

  return (
    <FullScreenDrawer 
      open={open} 
      onClose={onClose}
      title="Детали бронирования"
    >
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4, mb: 8 }}>
        <Paper sx={{ p: 3 }}>
          {booking && office && (
            <BookingMainInfo booking={booking} office={office} />
          )}
          
          {booking.status === 'NEW' && 
           booking.passes && 
           Object.keys(booking.passes).length > 0 &&
           ['CONFIRMED', 'AUTHORIZED'].includes(booking.payment?.status || '') && (
              <AccessCodes passes={booking.passes} timezone={booking.timezone} />
           )}
          <PaymentInfo 
            booking={{
              ...booking,
              totalPrice: booking.payment?.amount || booking.totalPrice
            }} 
            onPaymentClick={() => setPaymentDrawerOpen(true)} 
          />

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {canStart(booking) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDrawerType('start')}
                  disabled={isStartDisabled(booking, office || undefined)}
                  title={getStartButtonTooltip(booking)}
                >
                  {isStartDisabled(booking, office || undefined) && booking && office
                    ? `Начать (${formatTimeLeft(new Date(booking.startTime.getTime() - (office.duration?.pause || 0) * 60 * 1000))})` 
                    : 'Начать аренду'}
                </Button>
              )}
              {canComplete(booking) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDrawerType('completion')}
                >
                  Завершить аренду
                </Button>
              )}
              {canCancel(booking) && (
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={() => openCancelDrawer(booking)}
                >
                  Отменить
                </Button>
              )}
            </Box>
          </Box>
        </Paper>

        <CancelBookingDrawer
          open={isCancelDrawerOpen}
          onClose={closeCancelDrawer}
          onOpen={() => {}}
          booking={booking}
          comment={cancelComment}
          onCommentChange={setCancelComment}
          onCancel={handleCancel}
          isLoading={isCancelling}
          error={cancelError?.toString() || null}
        />

        <BookingCheckInDrawer
          open={drawerType !== null}
          onClose={handleDrawerClose}
          onSubmit={drawerType === 'start' ? handleStart : handleComplete}
          title={drawerType === 'start' ? 'Начало аренды' : 'Завершение аренды'}
          description={
            drawerType === 'start' 
              ? "Пожалуйста, сделайте фотографии помещения перед началом использования:"
              : "Пожалуйста, сделайте фотографии помещения и при необходимости оставьте комментарий:"
          }
          submitButtonText={drawerType === 'start' ? 'Начать' : 'Завершить'}
          isLoading={drawerType === 'start' ? startMutation.isPending : completeMutation.isPending}
          bookingId={bookingId || ''}
          type={drawerType || 'start'}
        />

        <PaymentDrawer
          open={paymentDrawerOpen}
          onClose={() => setPaymentDrawerOpen(false)}
          onOpen={() => setPaymentDrawerOpen(true)}
          paymentUrl={booking?.payment?.sbpUrl || ''}
        />
      </Box>
    </FullScreenDrawer>
  );
};

export default BookingDetails;
