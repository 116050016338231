import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SwipeableDrawer, Box, Typography, TextField, Button, CircularProgress, Alert } from '@mui/material';
export const CancelBookingDrawer = ({ open, onClose, onOpen, booking, comment, onCommentChange, onCancel, isLoading, error }) => {
    return (_jsx(SwipeableDrawer, Object.assign({ anchor: "bottom", open: open, onClose: onClose, onOpen: onOpen, swipeAreaWidth: 0, disableSwipeToOpen: true }, { children: _jsxs(Box, Object.assign({ sx: { p: 2 } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", gutterBottom: true }, { children: "\u041E\u0442\u043C\u0435\u043D\u0430 \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F" })), error && (_jsx(Alert, Object.assign({ severity: "error", sx: { mb: 2 } }, { children: error }))), _jsx(TextField, { fullWidth: true, label: "\u041F\u0440\u0438\u0447\u0438\u043D\u0430 \u043E\u0442\u043C\u0435\u043D\u044B", value: comment, onChange: (e) => onCommentChange(e.target.value), disabled: isLoading, required: true, error: !!error && !comment.trim(), helperText: !comment.trim() ? 'Обязательное поле' : '', sx: { mb: 2 } }), _jsxs(Box, Object.assign({ sx: {
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', sm: 'row' }
                    } }, { children: [_jsx(Button, Object.assign({ onClick: onClose, disabled: isLoading, fullWidth: true, sx: { order: { xs: 2, sm: 1 } } }, { children: "\u041D\u0435 \u043E\u0442\u043C\u0435\u043D\u044F\u0442\u044C" })), _jsx(Button, Object.assign({ variant: "contained", color: "error", onClick: onCancel, disabled: isLoading, startIcon: isLoading ? _jsx(CircularProgress, { size: 20 }) : null, fullWidth: true, sx: { order: { xs: 1, sm: 2 } } }, { children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435" }))] }))] })) })));
};
