import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import OfficeBookingWizard from './components/OfficeBookingWizard/OfficeBookingWizard';
import BookingsList from './components/BookingsList';
import Home from './components/Home';
import { Office } from 'shared-lib';
import Login from './components/Login';
import Profile from './components/Profile';
import { useAuth } from 'shared-lib';
import { OfficeService } from 'shared-lib';
import Settings from './components/Settings';
import ServiceList from './components/ServiceList';
import ServiceEdit from './components/ServiceEdit';
import useLoadingTracker from './hooks/useLoadingTracker';
import LocationsPage from './components/locations/LocationsPage';

const AppContent: React.FC = () => {
  const { user, loading } = useAuth();
  const [selectedOffice, setSelectedOffice] = useState<Office | null>(null);
  const [isTimeSlotSelectorOpen, setIsTimeSlotSelectorOpen] = useState(false);
  const [offices, setOffices] = useState<Office[]>([]);
  const [isLoadingOffices, setIsLoadingOffices] = useState(true);

  // Отслеживаем состояние загрузки для Вебвизора
  useLoadingTracker(isLoadingOffices, 'AppContent-Offices');
  useLoadingTracker(loading, 'AppContent-Auth');

  const officeService = OfficeService.getInstance();

  const fetchOffices = useCallback(async () => {
    try {
      const fetchedOffices = await officeService.fetchOffices();
      setOffices(fetchedOffices);
    } catch (error) {
      console.error("Error fetching offices:", error);
    } finally {
      setIsLoadingOffices(false);
    }
  }, [officeService]);

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);

  const handleSelectOffice = (office: Office) => {
    setSelectedOffice(office);
    setIsTimeSlotSelectorOpen(true);
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={
          <Home 
            offices={offices} 
            onSelectOffice={handleSelectOffice}
            isLoading={isLoadingOffices}
          />
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/locations" element={<LocationsPage />} />
        <Route path="/locations/:locationId" element={<LocationsPage />} />
        <Route path="/bookings/*" element={
          <ProtectedRoute>
            <Routes>
              <Route path="/" element={<BookingsList />} />
              <Route path=":bookingId" element={<BookingsList />} />
            </Routes>
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />
        <Route path="/settings" element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/settings/services" element={
          <ProtectedRoute>
            <ServiceList />
          </ProtectedRoute>
        } />
        <Route path="/settings/services/:serviceId" element={
          <ProtectedRoute>
            <ServiceEdit />
          </ProtectedRoute>
        } />
        <Route path="/settings/services/new" element={
          <ProtectedRoute>
            <ServiceEdit />
          </ProtectedRoute>
        } />
      </Routes>
      {selectedOffice && isTimeSlotSelectorOpen && (
          <OfficeBookingWizard
            office={selectedOffice}
            onClose={() => setIsTimeSlotSelectorOpen(false)}
            user={user}
          />
      )}
    </>
  );
};

export default AppContent;
