import { useEffect, useRef } from 'react';
import { updateWebvisorState } from '../utils/metrika';

/**
 * Хук для отслеживания состояния загрузки и обновления Вебвизора
 * @param isLoading - Состояние загрузки
 * @param componentName - Имя компонента (для отладки)
 */
const useLoadingTracker = (isLoading: boolean, componentName?: string): void => {
  const prevLoadingState = useRef(isLoading);

  useEffect(() => {
    // Если состояние загрузки изменилось с true на false (загрузка завершена)
    if (prevLoadingState.current === true && isLoading === false) {
      // Обновляем состояние Вебвизора
      updateWebvisorState();
      
      if (process.env.NODE_ENV === 'development' && componentName) {
        console.log(`[Metrika] ${componentName} loading completed, Webvisor state updated`);
      }
    }
    
    // Обновляем предыдущее состояние
    prevLoadingState.current = isLoading;
  }, [isLoading, componentName]);
};

export default useLoadingTracker; 