import React from 'react';
import { Paper } from '@mui/material';
import { Booking, Office } from 'shared-lib';
import { BookingMainInfo } from 'shared-lib';

interface BookingItemProps {
  booking: Booking;
  offices: Office[];
  onClick: () => void;
  isPast?: boolean;
  isToday?: boolean;
}

const BookingItem: React.FC<BookingItemProps> = ({ booking, offices, onClick, isPast, isToday }) => {
  const office = offices.find(o => o.id === booking.officeId);
  
  const isPaymentExpiringSoon = () => {
    if (!booking.createdAt || 
        !booking.payment || 
        booking.status === 'CANCELLED' ||
        ['CONFIRMED', 'AUTHORIZED'].includes(booking.payment.status) ||
        booking.payment.method === 'FREE') {
      return false;
    }

    const deadline = new Date(new Date(booking.createdAt).getTime() + 60 * 60 * 1000);
    const now = new Date();
    return now < deadline;
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 2, 
        mb: 2, 
        cursor: 'pointer',
        backgroundColor: isToday ? 'rgba(255, 255, 0, 0.1)' : 
                      isPast ? 'rgba(0, 0, 0, 0.05)' : 
                      isPaymentExpiringSoon() ? 'rgba(255, 152, 0, 0.1)' : 
                      'white',
        borderLeft: isPaymentExpiringSoon() ? '4px solid #ff9800' : 'none'
      }} 
      onClick={onClick}
    >
      <BookingMainInfo 
        booking={booking} 
        office={office}
        compact={true}
      />
    </Paper>
  );
};

export default BookingItem;
