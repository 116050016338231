import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Box, Alert, AlertTitle, Button } from '@mui/material';
import { PaymentMethod } from '../../types/types';
const formatPaymentTimeLeft = (createdAt) => {
    const deadline = new Date(createdAt.getTime() + 60 * 60 * 1000); // +1 час
    const now = new Date();
    const diffMs = deadline.getTime() - now.getTime();
    const diffMinutes = Math.ceil(diffMs / (1000 * 60));
    if (diffMinutes <= 0)
        return '';
    const minutes = diffMinutes % 60;
    const hours = Math.floor(diffMinutes / 60);
    if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    }
    return `${minutes} мин`;
};
const isPaymentExpired = (createdAt) => {
    const deadline = new Date(createdAt.getTime() + 60 * 60 * 1000); // +1 час
    return new Date() > deadline;
};
export const PaymentInfo = ({ booking, onPaymentClick }) => {
    if (!booking.payment ||
        booking.status === 'CANCELLED' ||
        booking.payment.status !== 'PENDING' ||
        booking.payment.method === PaymentMethod.FREE) {
        return null;
    }
    const formatPrice = (price) => `${(price / 100).toFixed(0)} ₽`;
    return (_jsx(Box, Object.assign({ sx: { mt: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 } }, { children: isPaymentExpired(new Date(booking.createdAt)) ? (_jsxs(Alert, Object.assign({ severity: "error" }, { children: [_jsx(AlertTitle, { children: "\u0412\u0440\u0435\u043C\u044F \u043D\u0430 \u043E\u043F\u043B\u0430\u0442\u0443 \u0438\u0441\u0442\u0435\u043A\u043B\u043E" }), _jsx(Typography, { children: "\u041A \u0441\u043E\u0436\u0430\u043B\u0435\u043D\u0438\u044E, \u0432\u0440\u0435\u043C\u044F \u043D\u0430 \u043E\u043F\u043B\u0430\u0442\u0443 \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0438\u0441\u0442\u0435\u043A\u043B\u043E. \u0411\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u043F\u043E\u0441\u0442\u0430\u0432\u043B\u0435\u043D\u043E \u0432 \u043E\u0447\u0435\u0440\u0435\u0434\u044C \u043D\u0430 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u043E\u0442\u043C\u0435\u043D\u0443." })] }))) : (_jsxs(_Fragment, { children: [_jsx(Alert, Object.assign({ severity: "warning" }, { children: _jsxs(Typography, { children: ["\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043E\u043F\u043B\u0430\u0442\u0438\u0442\u0435 \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u0432 \u0442\u0435\u0447\u0435\u043D\u0438\u0435 \u0447\u0430\u0441\u0430 \u043F\u043E\u0441\u043B\u0435 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044F. \u041E\u0441\u0442\u0430\u043B\u043E\u0441\u044C: ", _jsx("strong", Object.assign({ "data-testid": "payment-timer" }, { children: formatPaymentTimeLeft(new Date(booking.createdAt)) }))] }) })), _jsx(Box, Object.assign({ sx: { mt: 3 } }, { children: booking.payment.sbpUrl ? (_jsxs(Button, Object.assign({ variant: "contained", color: "primary", fullWidth: true, onClick: onPaymentClick }, { children: ["\u041E\u043F\u043B\u0430\u0442\u0438\u0442\u044C ", formatPrice(booking.totalPrice)] }))) : null }))] })) })));
};
export default PaymentInfo;
