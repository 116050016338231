/**
 * Utility functions for Yandex Metrika
 */

// Type definition for the Yandex Metrika counter
declare global {
  interface Window {
    ym: (
      counterId: number, 
      eventType: string, 
      eventName?: string | Record<string, any>, 
      params?: any
    ) => void;
  }
}

const PROD_COUNTER_ID = '100286382';
// Yandex Metrika counter ID from environment variable
const COUNTER_ID = parseInt(process.env.REACT_APP_YANDEX_METRIKA_ID || PROD_COUNTER_ID, 10);

/**
 * Send a hit to Yandex Metrika for the current page
 * @param url - The URL to send a hit for (defaults to current URL)
 */
export const sendPageView = (url?: string): void => {
  if (typeof window.ym !== 'function') {
    console.warn('Yandex Metrika not initialized');
    return;
  }

  try {
    const pageUrl = url || window.location.pathname + window.location.search;
    
    // Отправляем hit с дополнительными параметрами для лучшей работы Вебвизора
    window.ym(COUNTER_ID, 'hit', pageUrl, {
      title: document.title,
      referer: document.referrer
    });
  } catch (error) {
    console.error('Error sending page view to Yandex Metrika:', error);
  }
};

/**
 * Send a custom event to Yandex Metrika
 * @param eventName - Name of the event
 * @param params - Additional parameters for the event
 */
export const sendEvent = (eventName: string, params?: any): void => {
  if (typeof window.ym !== 'function') {
    console.warn('Yandex Metrika not initialized');
    return;
  }

  try {
    window.ym(COUNTER_ID, 'reachGoal', eventName, params);
  } catch (error) {
    console.error('Error sending event to Yandex Metrika:', error);
  }
};

/**
 * Обновляет состояние Вебвизора
 * Используется для обновления состояния Вебвизора после загрузки данных
 */
export const updateWebvisorState = (): void => {
  if (typeof window.ym !== 'function') {
    console.warn('Yandex Metrika not initialized');
    return;
  }

  try {
    // Отправляем событие notBounce для обновления состояния Вебвизора
    window.ym(COUNTER_ID, 'notBounce');
    
    // Также отправляем пользовательское событие для отладки
    window.ym(COUNTER_ID, 'reachGoal', 'content_loaded');
  } catch (error) {
    console.error('Error updating Webvisor state:', error);
  }
};

/**
 * Set user ID for Yandex Metrika
 * This helps track authenticated users across sessions
 * @param userId - The ID of the authenticated user
 */
export const setUserId = (userId: string): void => {
  if (typeof window.ym !== 'function') {
    console.warn('Yandex Metrika not initialized');
    return;
  }

  try {
    window.ym(COUNTER_ID, 'setUserID', userId);
    console.log('Yandex Metrika: User ID set', userId);
  } catch (error) {
    console.error('Error setting user ID in Yandex Metrika:', error);
  }
};

/**
 * Set user parameters for Yandex Metrika
 * This allows tracking custom user parameters for better analytics
 * @param params - Object with user parameters
 */
export const setUserParams = (params: Record<string, any>): void => {
  if (typeof window.ym !== 'function') {
    console.warn('Yandex Metrika not initialized');
    return;
  }

  try {
    // The correct syntax for userParams according to Yandex Metrika API
    window.ym(COUNTER_ID, 'params', params);
    console.log('Yandex Metrika: User params set', params);
  } catch (error) {
    console.error('Error setting user params in Yandex Metrika:', error);
  }
}; 