import React from 'react';
import { Office } from 'shared-lib';
import OfficeList from './OfficeList';
import useLoadingTracker from '../hooks/useLoadingTracker';

interface HomeProps {
  offices: Office[];
  onSelectOffice: (office: Office) => void;
  isLoading: boolean;
}

const Home: React.FC<HomeProps> = ({ offices, onSelectOffice, isLoading }) => {
  // Отслеживаем состояние загрузки для Вебвизора
  useLoadingTracker(isLoading, 'Home');

  return (
    <div>
      <OfficeList 
        offices={offices} 
        onSelectOffice={onSelectOffice}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Home;
