import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  Container, 
  Skeleton, 
  Box, 
  Chip,
  Button
} from '@mui/material';
import { Location } from 'shared-lib';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface LocationStatus {
  role?: 'resident' | 'admin' | 'privileged' | 'blocked';
  requestStatus?: 'pending' | 'approved' | 'rejected';
}

const LocationSkeleton: React.FC = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Skeleton variant="rectangular" height={200} sx={{ mb: 1, borderRadius: 1 }} />
    <Skeleton variant="text" width="70%" height={32} sx={{ mb: 0.5 }} />
    <Skeleton variant="text" width="40%" height={24} />
  </Box>
);

interface LocationItemProps {
  location: Location;
  status?: LocationStatus;
  onClick: (location: Location) => void;
}

const LocationItem: React.FC<LocationItemProps> = ({ location, status, onClick }) => {
  const getStatusChip = () => {
    if (status?.role === 'blocked') {
      return <Chip color="error" size="small" label="Заблокирован" />;
    }
    
    if (status?.role === 'admin') {
      return <Chip color="primary" size="small" label="Администратор" />;
    }
    
    if (status?.role === 'privileged') {
      return <Chip color="success" size="small" label="Привилегированный резидент" />;
    }
    
    if (status?.role === 'resident') {
      return <Chip color="success" size="small" label="Резидент" />;
    }
    
    if (status?.requestStatus === 'pending') {
      return <Chip color="warning" size="small" label="Запрос на рассмотрении" />;
    }
    
    if (status?.requestStatus === 'rejected') {
      return <Chip color="error" size="small" label="Запрос отклонен" />;
    }
    
    return null;
  };
  
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 6
        }
      }}
      onClick={() => onClick(location)}
    >
      <CardMedia
        component="img"
        height="200"
        image={location.images?.[0] || 'https://via.placeholder.com/640x360?text=Локация'}
        alt={location.name}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Typography variant="h6" component="h2" gutterBottom sx={{ mb: 0 }}>
            {location.name}
          </Typography>
          {getStatusChip()}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <LocationOnIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {location.address}
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {location.description || 'Нет описания'}
        </Typography>
        <Button 
          size="small" 
          variant="outlined" 
          sx={{ mt: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
            onClick(location);
          }}
        >
          Подробнее
        </Button>
      </CardContent>
    </Card>
  );
};

interface LocationListProps {
  locations: Location[];
  statuses?: Record<string, LocationStatus>;
  isLoading?: boolean;
  onSelectLocation: (location: Location) => void;
}

const LocationList: React.FC<LocationListProps> = ({ 
  locations, 
  statuses = {}, 
  isLoading = false,
  onSelectLocation
}) => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Доступные локации
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          // Показываем 3 скелетона во время загрузки
          [1, 2, 3].map((i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <LocationSkeleton />
            </Grid>
          ))
        ) : locations.length > 0 ? (
          locations.map(location => (
            <Grid item xs={12} sm={6} md={4} key={location.id}>
              <LocationItem 
                location={location} 
                status={statuses[location.id]} 
                onClick={onSelectLocation}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center" sx={{ my: 4 }}>
              Локации не найдены
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LocationList; 