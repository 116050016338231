import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from 'shared-lib';
import AppContent from './AppContent';
import { GoogleCalendarCallback, GoogleCalendarError } from 'shared-lib';
import BookingPage from './components/BookingPage';
import Layout from './components/Layout';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import PublicOffer from './components/legal/PublicOffer';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useYandexMetrika from './hooks/useYandexMetrika';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // 1 minute
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <AppWithAuth />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

const AppWithAuth: React.FC = () => {
  const { user } = useAuth();
  // Используем хук для отслеживания переходов между страницами
  useYandexMetrika();

  return (
    <FeatureFlagProvider 
      clientKey={process.env.REACT_APP_LAUNCHDARKLY_CLIENT_KEY!}
      userId={user?.uid || 'anonymous'}
      userEmail={user?.email || ''}
    >
      <Routes>
        <Route path="/book/:userId/:serviceId" element={<BookingPage />} />
        <Route path="/oauth2callback" element={<GoogleCalendarCallback />} />
        <Route path="/google-calendar-error" element={<GoogleCalendarError />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/offer" element={<PublicOffer />} />
        <Route
          path="/*"
          element={<Layout />}
        >
          <Route path="*" element={<AppContent />} />
        </Route>
      </Routes>
    </FeatureFlagProvider>
  );
};

export default App;
