import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Location } from 'shared-lib';
import LocationList from './LocationList';
import LocationDetails from './LocationDetails';
import { usePublicLocations } from '../../hooks/useLocationQueries';
import OfficeList from '../OfficeList';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationService } from 'shared-lib';
import { useQuery } from '@tanstack/react-query';

// Этот интерфейс используется в LocationList, не удаляем его
// interface LocationStatus {
//   role?: 'resident' | 'admin' | 'privileged' | 'blocked';
//   requestStatus?: 'pending' | 'approved' | 'rejected';
// }

enum ViewMode {
  LIST,
  DETAILS,
  OFFICES
}

const LocationsPage: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.LIST);
  const navigate = useNavigate();
  const { locationId } = useParams<{ locationId?: string }>();
  
  const { 
    data: locations = [], 
    isLoading: isLocationsLoading,
    error: locationsError
  } = usePublicLocations();
  
  // Получаем детали конкретной локации по ID из URL
  const {
    data: locationDetails,
    isLoading: isLocationDetailsLoading
  } = useQuery({
    queryKey: ['location', locationId],
    queryFn: async () => {
      if (!locationId) return null;
      const locationService = LocationService.getInstance();
      return await locationService.getLocationById(locationId);
    },
    enabled: !!locationId
  });
  
  // Если есть locationId в URL, устанавливаем выбранную локацию и режим просмотра
  useEffect(() => {
    if (locationDetails) {
      setSelectedLocation(locationDetails);
      setViewMode(ViewMode.DETAILS);
    }
  }, [locationDetails]);
  
  const handleSelectLocation = (location: Location) => {
    setSelectedLocation(location);
    setViewMode(ViewMode.DETAILS);
    // Обновляем URL при выборе локации
    navigate(`/locations/${location.id}`);
  };
  
  const handleBackToList = () => {
    setViewMode(ViewMode.LIST);
    setSelectedLocation(null);
    // Обновляем URL при возврате к списку
    navigate('/locations');
  };
  
  const handleViewOffices = (location: Location) => {
    setSelectedLocation(location);
    setViewMode(ViewMode.OFFICES);
  };

  const handleSelectOffice = (office: any) => {
    // Используем существующую логику для выбора офиса
    navigate('/');
    // Здесь должен быть код для открытия диалогового окна бронирования
  };
  
  if (locationId && isLocationDetailsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (locationsError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography color="error">
          Ошибка при загрузке локаций: {(locationsError as Error).message}
        </Typography>
      </Box>
    );
  }
  
  const renderContent = () => {
    switch (viewMode) {
      case ViewMode.DETAILS:
        if (!selectedLocation) return null;
        return (
          <LocationDetails 
            location={selectedLocation} 
            onBack={handleBackToList}
            onViewOffices={handleViewOffices}
          />
        );
        
      case ViewMode.OFFICES:
        if (!selectedLocation) return null;
        return (
          <Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Офисы в локации: {selectedLocation.name}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'primary.main', 
                    cursor: 'pointer',
                    display: 'inline-block'
                  }}
                  onClick={() => setViewMode(ViewMode.DETAILS)}
                >
                  &larr; Вернуться к информации о локации
                </Typography>
              </Box>
            </Box>
            <OfficeList 
              locationId={selectedLocation.id}
              offices={[]} 
              onSelectOffice={handleSelectOffice}
              isLoading={false}
            />
          </Box>
        );
        
      case ViewMode.LIST:
      default:
        return (
          <LocationList 
            locations={locations} 
            statuses={{}}
            isLoading={isLocationsLoading}
            onSelectLocation={handleSelectLocation}
          />
        );
    }
  };
  
  return (
    <Box>
      {renderContent()}
    </Box>
  );
};

export default LocationsPage; 