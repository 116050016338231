import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, CircularProgress, Button, Skeleton } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { auth } from 'shared-lib';
import { Booking, Office } from 'shared-lib';
import { BookingService } from '../services/BookingService';
import { OfficeService } from 'shared-lib';
import BookingItem from './BookingItem';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BookingDetails from './BookingDetails';
import useLoadingTracker from '../hooks/useLoadingTracker';

const BookingSkeleton: React.FC = () => (
  <Box sx={{ mb: 2 }}>
    <Skeleton variant="rectangular" height={100} sx={{ mb: 1, borderRadius: 1 }} />
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="20%" />
    </Box>
  </Box>
);

const BookingsList: React.FC = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams<{ bookingId: string }>();
  const location = useLocation();

  const [bookings, setBookings] = useState<Booking[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [showPastBookings, setShowPastBookings] = useState(false);
  const [pastBookings, setPastBookings] = useState<Booking[]>([]);
  const [hasMorePastBookings, setHasMorePastBookings] = useState(true);
  const [isLoadingPastBookings, setIsLoadingPastBookings] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);

  // Отслеживаем состояние загрузки для Вебвизора
  useLoadingTracker(isLoading, 'BookingsList');
  useLoadingTracker(isLoadingPastBookings, 'BookingsList-Past');

  const bookingService = BookingService.getInstance();
  const officeService = OfficeService.getInstance();

  const fetchBookings = useCallback(async () => {
    if (!auth.currentUser) return;

    try {
      const lastBooking = bookings.length > 0 ? bookings[bookings.length - 1] : null;
      const fetchedBookings = await bookingService.fetchUserBookings(auth.currentUser.uid, lastBooking);
      console.log('Fetched bookings:', fetchedBookings);
      
      if (fetchedBookings.length === 0) {
        setHasMore(false);
      } else {
        setBookings(prevBookings => {
          const newBookings = [...prevBookings, ...fetchedBookings];
          const uniqueBookings = newBookings.filter((booking, index, self) =>
            index === self.findIndex((t) => t.id === booking.id)
          );
          return uniqueBookings;
        });
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setError('Ошибка при загрузке бронирований');
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [bookings, bookingService]);

  useEffect(() => {
    if (!auth.currentUser) {
      setError('Пользователь не авторизован');
      setIsLoading(false);
      return;
    }

    const fetchOffices = async () => {
      try {
        const fetchedOffices = await officeService.fetchOffices();
        console.log('Fetched offices:', fetchedOffices);
        setOffices(fetchedOffices);
      } catch (error) {
        console.error("Error fetching offices:", error);
      }
    };

    fetchOffices();
    fetchBookings();
  }, [fetchBookings, officeService]);

  const fetchPastBookings = async (loadMore = false) => {
    if (!auth.currentUser) return;
    setIsLoadingPastBookings(true);

    try {
      const limit = loadMore ? pastBookings.length + 11 : 11;
      const fetchedPastBookings = await bookingService.fetchPastUserBookings(auth.currentUser.uid, limit);
      console.log('Fetched past bookings:', fetchedPastBookings);
      
      setHasMorePastBookings(fetchedPastBookings.length === limit);
      
      const bookingsToShow = hasMorePastBookings ? 
        fetchedPastBookings.slice(0, -1) : 
        fetchedPastBookings;
      
      setPastBookings(bookingsToShow);
      setShowPastBookings(true);
    } catch (error) {
      console.error("Error fetching past bookings:", error);
    } finally {
      setIsLoadingPastBookings(false);
    }
  };

  const loadMorePastBookings = () => {
    fetchPastBookings(true);
  };

  const now = new Date();
  const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

  const todayBookings = bookings.filter(booking => {
    const bookingDate = booking.startTime;
    return bookingDate >= todayStart && bookingDate <= todayEnd;
  });

  const futureBookings = bookings.filter(booking => 
    new Date(booking.startTime) > todayEnd
  );

  const filterCancelled = useCallback((bookings: Booking[]) => 
    bookings.filter(booking => showCancelled || booking.status !== 'CANCELLED'),
    [showCancelled]
  );

  const hiddenCancelledCount = 
    todayBookings.filter(b => b.status === 'CANCELLED').length + 
    bookings.filter(booking => 
      new Date(booking.startTime) > todayEnd && booking.status === 'CANCELLED'
    ).length +
    pastBookings.filter(b => b.status === 'CANCELLED').length;

  const handleBookingClick = (bookingId: string) => {
    navigate(bookingId);
  };

  const handleCloseBookingDetails = () => {
    navigate('/bookings', { replace: true });
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <Box sx={{ p: 3, maxWidth: 600, margin: '0 auto' }}>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
            {[1, 2, 3].map((i) => (
              <BookingSkeleton key={i} />
            ))}
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              {!showPastBookings && (
                <Button onClick={() => fetchPastBookings()}>
                  Завершённые
                </Button>
              )}
              <Button 
                onClick={() => setShowCancelled(!showCancelled)}
                color={showCancelled ? "primary" : "secondary"}
              >
                {showCancelled ? 'Скрыть отмененные' : `Отмененные${hiddenCancelledCount > 0 ? ` (${hiddenCancelledCount})` : ''}`}
              </Button>
            </Box>
            {showPastBookings && filterCancelled(pastBookings).length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>Прошлые бронирования</Typography>
                {isLoadingPastBookings ? (
                  [1, 2].map((i) => <BookingSkeleton key={`past-${i}`} />)
                ) : (
                  <>
                    {hasMorePastBookings && (
                      <Button 
                        onClick={loadMorePastBookings}
                        disabled={isLoadingPastBookings}
                        sx={{ mb: 2 }}
                        fullWidth
                      >
                        {isLoadingPastBookings ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Загрузить ещё'
                        )}
                      </Button>
                    )}
                    {[...filterCancelled(pastBookings)].reverse().map((booking) => (
                      <BookingItem 
                        key={booking.id} 
                        booking={booking} 
                        offices={offices} 
                        isPast={true} 
                        onClick={() => handleBookingClick(booking.id)} 
                      />
                    ))}
                  </>
                )}
              </Box>
            )}
            {filterCancelled(todayBookings).length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>Сегодняшние бронирования</Typography>
                {filterCancelled(todayBookings).map((booking) => (
                  <BookingItem 
                    key={booking.id} 
                    booking={booking} 
                    offices={offices} 
                    isToday={true} 
                    onClick={() => handleBookingClick(booking.id)} 
                  />
                ))}
              </Box>
            )}
            <Typography variant="h6" gutterBottom>Предстоящие бронирования</Typography>
            <InfiniteScroll
              dataLength={bookings.length}
              next={fetchBookings}
              hasMore={hasMore}
              loader={
                <Box sx={{ mt: 2 }}>
                  <BookingSkeleton />
                </Box>
              }
              endMessage={<Typography align="center">Больше бронирований нет</Typography>}
            >
              {filterCancelled(futureBookings).map((booking) => (
                <BookingItem 
                  key={booking.id} 
                  booking={booking} 
                  offices={offices} 
                  onClick={() => handleBookingClick(booking.id)}
                />
              ))}
            </InfiniteScroll>
            {filterCancelled(futureBookings).length === 0 && !hasMore && (
              <Typography align="center">
                {!showCancelled && hiddenCancelledCount > 0 
                  ? `Нет активных бронирований (${hiddenCancelledCount} отменено)` 
                  : 'Нет предстоящих бронирований'}
              </Typography>
            )}
          </>
        )}
      </Box>

      <BookingDetails
        open={!!bookingId}
        bookingId={bookingId || ''}
        onClose={handleCloseBookingDetails}
        location={location}
      />
    </>
  );
};

export default BookingsList;
