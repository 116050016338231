import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, CardMedia, Typography, Button, Box, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const OfficeItem = ({ office, onSelect }) => {
    const placeholderImage = 'https://via.placeholder.com/300x200?text=Изображение+недоступно';
    const formatPrice = (price) => {
        return `${(price / 100).toFixed(0)} ₽`;
    };
    const formatDurationText = (minutes, withFrom = false) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const formatHours = (h) => {
            if (withFrom) {
                if (h === 1)
                    return '1 часа';
                return `${h} часов`;
            }
            if (h === 1)
                return '1 час';
            if (h > 1 && h < 5)
                return `${h} часа`;
            return `${h} часов`;
        };
        const formatMinutes = (m) => {
            if (m === 1)
                return '1 минута';
            if (m > 1 && m < 5)
                return `${m} минуты`;
            if (m > 20 && m % 10 === 1)
                return `${m} минута`;
            if (m > 20 && m % 10 > 1 && m % 10 < 5)
                return `${m} минуты`;
            return `${m} минут`;
        };
        if (hours === 0)
            return formatMinutes(minutes);
        if (remainingMinutes === 0)
            return formatHours(hours);
        return `${formatHours(hours)} ${formatMinutes(remainingMinutes)}`;
    };
    const formatDuration = () => {
        const duration = office.duration.duration_without_pause;
        const durationText = formatDurationText(duration);
        return (_jsxs(Box, Object.assign({ sx: {
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                color: 'text.secondary',
                typography: 'body2'
            } }, { children: [_jsx(AccessTimeIcon, { sx: { fontSize: '1rem', mr: 0.5 } }), durationText, office.duration.pause > 0 && (_jsxs(_Fragment, { children: [" \u2022 \u043F\u0435\u0440\u0435\u0440\u044B\u0432 ", office.duration.pause, " \u043C\u0438\u043D\u0443\u0442"] }))] })));
    };
    const formatPrices = () => {
        const basePrice = office.price.base_price;
        const multipleSlotPrice = office.price.multiple_slot_price;
        const dayPrice = office.price.day_price;
        const duration = office.duration.duration_without_pause;
        const minSlots = office.bookingSettings.minimum_slots;
        if (!basePrice || basePrice === 0)
            return null;
        return (_jsxs(Box, Object.assign({ sx: {
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: 1.5,
                '& > *': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    minWidth: 'fit-content'
                }
            } }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h6" }, { children: formatPrice(basePrice) })), _jsx(Chip, { icon: _jsx(AccessTimeIcon, {}), label: formatDurationText(duration), size: "small", variant: "outlined" })] }), multipleSlotPrice && multipleSlotPrice > 0 && multipleSlotPrice < basePrice && (_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: formatPrice(multipleSlotPrice * minSlots) })), _jsx(Chip, { icon: _jsx(AccessTimeIcon, {}), label: formatDurationText(duration * minSlots), size: "small", color: "primary", variant: "outlined" })] })), typeof dayPrice === 'number' && dayPrice > 0 && dayPrice < (basePrice * 9) && (_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h6", color: "primary" }, { children: formatPrice(dayPrice) })), _jsx(Chip, { icon: _jsx(AccessTimeIcon, {}), label: "9 \u0447\u0430\u0441\u043E\u0432", size: "small", color: "primary", variant: "outlined" })] }))] })));
    };
    return (_jsxs(Card, { children: [_jsx(CardMedia, { component: "img", height: "200", image: office.images && office.images.length > 0 ? office.images[0] : placeholderImage, alt: office.name }), _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ gutterBottom: true, variant: "h5", component: "div" }, { children: office.name })), office.description && (_jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary", sx: { mb: 1 } }, { children: office.description }))), office.duration.pause > 0 && (_jsxs(Typography, Object.assign({ variant: "body2", color: "text.secondary", sx: {
                            mb: 2,
                            pb: 1,
                            borderBottom: '1px solid',
                            borderColor: 'divider'
                        } }, { children: ["\u0414\u043E \u0438 \u043F\u043E\u0441\u043B\u0435 \u043A\u0430\u0436\u0434\u043E\u0433\u043E \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F \u0434\u0435\u043B\u0430\u0435\u0442\u0441\u044F \u043F\u0435\u0440\u0435\u0440\u044B\u0432 ", office.duration.pause, " \u043C\u0438\u043D\u0443\u0442"] }))), office.price.base_price > 0 && (_jsx(Box, Object.assign({ sx: { mb: 2 } }, { children: formatPrices() }))), _jsx(Button, Object.assign({ variant: "contained", color: "primary", fullWidth: true, onClick: () => onSelect(office) }, { children: "\u0417\u0430\u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }))] })] }));
};
export default OfficeItem;
