import React, { useState, useEffect } from 'react';
import { FullScreenDrawer } from 'shared-lib';

interface PaymentDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  paymentUrl: string;
}

const extractPaymentId = (url: string): string | null => {
  try {
    const match = url.match(/\/([A-Z0-9]+)\?/);
    return match ? match[1] : null;
  } catch (e) {
    console.error('Failed to extract payment ID:', e);
    return null;
  }
};

const checkPaymentStatus = async (paymentId: string) => {
  try {
    const response = await fetch(`https://widget.cbrpay.ru/v1/payment-link/${paymentId}`);
    const data = await response.json();
    console.log('Payment status response:', data);
    return data.code === 'RQ00000';
    // See also codes here https://sbp.nspk.ru/api/#section/Opisanie-polzovatelskih-scenariev/Registraciya-i-poluchenie-Platezhnyh-i-Informacionnyh-ssylok-SBP
  } catch (e) {
    console.error('Failed to check payment status:', e);
    return false;
  }
};

const PaymentDrawer: React.FC<PaymentDrawerProps> = ({
  open,
  onClose,
  onOpen,
  paymentUrl
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (open && paymentUrl) {
      setKey(prev => prev + 1);
      
      const paymentId = extractPaymentId(paymentUrl);
      if (paymentId) {
        checkPaymentStatus(paymentId);
      }
    }
  }, [open, paymentUrl]);

  return (
    <FullScreenDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      title="Оплата бронирования"
    >
      <iframe 
        key={key}
        src={paymentUrl || undefined}
        style={{
          width: '100%',
          height: '100%',
          border: 'none'
        }}
        title="payment"
      />
    </FullScreenDrawer>
  );
};

export default PaymentDrawer; 