import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '../config';
import { Timestamp } from 'firebase/firestore';
export const formatPrice = (price) => {
    return `${(price / 100).toFixed(0)} ${DEFAULT_CURRENCY}`;
};
export const formatDate = (date) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    return dateObj.toLocaleDateString(DEFAULT_LOCALE);
};
export const formatTime = (date, timezone) => {
    if (!date)
        return '';
    const dateObj = date instanceof Date ? date :
        date instanceof Timestamp ? date.toDate() :
            new Date(date);
    return dateObj.toLocaleTimeString(DEFAULT_LOCALE, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timezone
    });
};
export const formatTimeRange = (startTime, endTime, timezone) => {
    return `${formatTime(startTime, timezone)} - ${formatTime(endTime, timezone)}`;
};
export const formatCompactDateRange = (startDate, endDate, timezone) => {
    const startObj = startDate instanceof Date ? startDate :
        startDate instanceof Timestamp ? startDate.toDate() :
            new Date(startDate);
    const endObj = endDate instanceof Date ? endDate :
        endDate instanceof Timestamp ? endDate.toDate() :
            new Date(endDate);
    const isSameDay = startObj.toDateString() === endObj.toDateString();
    if (isSameDay) {
        // Если даты в один день, показываем дату один раз и интервал времени
        const weekday = startObj.toLocaleDateString('ru-RU', { weekday: 'short', timeZone: timezone });
        const day = startObj.getDate();
        const month = startObj.toLocaleDateString('ru-RU', { month: 'short', timeZone: timezone });
        const timeRange = formatTimeRange(startObj, endObj, timezone);
        return `${weekday}, ${day} ${month} ${timeRange}`;
    }
    else {
        // Если разные дни, показываем полный формат для обеих дат
        const formatFullDate = (date) => {
            const weekday = date.toLocaleDateString('ru-RU', { weekday: 'short', timeZone: timezone });
            const day = date.getDate();
            const month = date.toLocaleDateString('ru-RU', { month: 'short', timeZone: timezone });
            const time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit', timeZone: timezone });
            return `${weekday}, ${day} ${month} ${time}`;
        };
        return `${formatFullDate(startObj)} - ${formatFullDate(endObj)}`;
    }
};
