import React from 'react';
import { Grid, Typography, Container, Skeleton, Box } from '@mui/material';
import { Office, OfficeItem, LocationService } from 'shared-lib';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useQuery } from '@tanstack/react-query';

const OfficeSkeleton: React.FC = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Skeleton variant="rectangular" height={200} sx={{ mb: 1, borderRadius: 1 }} />
    <Skeleton variant="text" width="70%" height={32} sx={{ mb: 0.5 }} />
    <Skeleton variant="text" width="40%" height={24} />
  </Box>
);

// Компонент для отображения информации о локации
const LocationInfo: React.FC<{ locationId: string }> = ({ locationId }) => {
  const { data: location, isLoading } = useQuery({
    queryKey: ['location', locationId],
    queryFn: async () => {
      const locationService = LocationService.getInstance();
      return await locationService.getLocationById(locationId);
    },
    enabled: !!locationId
  });
  
  if (isLoading || !location) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocationOnIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
        <Skeleton width={100} />
      </Box>
    );
  }
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <LocationOnIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
      <Typography variant="body2" color="text.secondary">
        {location.name}
      </Typography>
    </Box>
  );
};

// Расширяем компонент OfficeItem для отображения локации
const EnhancedOfficeItem: React.FC<{ office: Office, onSelect: (office: Office) => void }> = ({ office, onSelect }) => {
  return (
    <Box sx={{ mb: 2 }}>
      {office.locationId && <LocationInfo locationId={office.locationId} />}
      <OfficeItem office={office} onSelect={onSelect} />
    </Box>
  );
};

interface OfficeListProps {
  offices: Office[];
  onSelectOffice: (office: Office) => void;
  isLoading?: boolean;
  locationId?: string;
}

const OfficeList: React.FC<OfficeListProps> = ({ 
  offices, 
  onSelectOffice,
  isLoading = false,
  locationId
}) => {
  // Если указан locationId и нет офисов, можно загрузить офисы для конкретной локации
  const { data: locationOffices = [], isLoading: isLocationOfficesLoading } = useQuery({
    queryKey: ['location-offices', locationId],
    queryFn: async () => {
      if (!locationId) return [];
      const locationService = LocationService.getInstance();
      return await locationService.fetchOfficesForLocation(locationId);
    },
    enabled: !!locationId && offices.length === 0
  });
  
  // Используем офисы из props или загруженные для локации
  const displayedOffices = offices.length > 0 ? offices : locationOffices;
  const isDataLoading = isLoading || isLocationOfficesLoading;
  
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Доступные кабинеты
      </Typography>
      <Grid container spacing={3}>
        {isDataLoading ? (
          // Показываем 3 скелетона во время загрузки
          [1, 2, 3].map((i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <OfficeSkeleton />
            </Grid>
          ))
        ) : displayedOffices.length > 0 ? (
          displayedOffices.map(office => (
            <Grid item xs={12} sm={6} md={4} key={office.id}>
              <EnhancedOfficeItem office={office} onSelect={onSelectOffice} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center" sx={{ my: 4 }}>
              Кабинеты не найдены
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OfficeList;
