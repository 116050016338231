var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
export const useCancelBooking = (onCancel) => {
    const [isOpen, setIsOpen] = useState(false);
    const [booking, setBooking] = useState(null);
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const openCancelDrawer = (booking) => {
        setBooking(booking);
        setComment('');
        setError(null);
        setIsOpen(true);
    };
    const closeCancelDrawer = () => {
        setIsOpen(false);
        setBooking(null);
        setComment('');
        setError(null);
    };
    const handleCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!booking)
            return;
        if (!comment.trim()) {
            setError('Пожалуйста, укажите причину отмены');
            return;
        }
        try {
            setIsLoading(true);
            setError(null);
            yield onCancel(booking.id, comment);
            closeCancelDrawer();
        }
        catch (err) {
            setError(err instanceof Error ? err.message : 'Ошибка при отмене бронирования');
        }
        finally {
            setIsLoading(false);
        }
    });
    return {
        isOpen,
        booking,
        comment,
        setComment,
        openCancelDrawer,
        closeCancelDrawer,
        handleCancel,
        isLoading,
        error
    };
};
