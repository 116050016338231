import React, { useRef, useEffect, useState } from 'react';
import { Typography, Box, Grid, Button, CircularProgress } from '@mui/material';
import { Day, Spot, Office } from 'shared-lib';
import BookingCalendar from './BookingCalendar';
import { formatDateToString } from '../utils/dateUtils';
import EndTimeDrawer from './EndTimeDrawer';

const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes} минут`;
  } else if (remainingMinutes === 0) {
    if (hours === 1) return '1 час';
    if (hours >= 2 && hours <= 4) return `${hours} часа`;
    return `${hours} часов`;
  } else {
    const hoursText = hours === 1 ? 'час' : hours >= 2 && hours <= 4 ? 'часа' : 'часов';
    return `${hours} ${hoursText} ${remainingMinutes} мин`;
  }
};

interface DateTimeSelectorProps {
  availableSlots: Day[];
  selectedDate: Date | null;
  selectedSlots: Spot[];
  onDateSelect: (date: Date) => void;
  onSlotsSelect: (slots: Spot[]) => void;
  isMobile: boolean;
  onMonthChange: (date: Date) => void;
  currentMonth: Date;
  isLoading?: boolean;
  allowMultipleSlots?: boolean;
  duration?: number;
  dayPrice?: number;
  minimumSlots?: number;
  office?: Office;
}

const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  availableSlots,
  selectedDate,
  selectedSlots,
  onDateSelect,
  onSlotsSelect,
  isMobile,
  onMonthChange,
  currentMonth,
  isLoading,
  allowMultipleSlots = false,
  duration = 60,
  dayPrice: propDayPrice,
  minimumSlots: propMinimumSlots,
  office,
}) => {
  const timeSlotsRef = useRef<HTMLDivElement>(null);
  const [showEndTimeDrawer, setShowEndTimeDrawer] = useState(false);
  const [previousSlots, setPreviousSlots] = useState<Spot[]>([]);

  // Получаем цены из office только если он есть и нужен множественный выбор
  const basePrice = office?.price.base_price || 0;
  const multipleSlotPrice = office?.price.multiple_slot_price || basePrice;
  const officeDayPrice = office?.price.day_price;
  const officeMinimumSlots = office?.bookingSettings?.minimum_slots || 1;

  // Используем значения из пропсов или из office
  const effectiveDayPrice = propDayPrice ?? officeDayPrice;
  const effectiveMinimumSlots = propMinimumSlots ?? officeMinimumSlots;

  useEffect(() => {
    if (isMobile && selectedDate && timeSlotsRef.current) {
      timeSlotsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedDate, isMobile]);

  const handleMonthChange = (newMonth: Date) => {
    if (selectedDate) {
        const currentDay = selectedDate.getDate();
        const lastDayOfNewMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0).getDate();
        const newDay = Math.min(currentDay, lastDayOfNewMonth);
        
        const newDate = new Date(newMonth.getFullYear(), newMonth.getMonth(), newDay);
        onDateSelect(newDate);
    }

    if (allowMultipleSlots && selectedSlots.length > 0) {
      onSlotsSelect([]);
    }
    onMonthChange(newMonth);
  };

  const handleSlotSelect = (slot: Spot) => {
    if (allowMultipleSlots) {
      setPreviousSlots(selectedSlots);
      onSlotsSelect([slot]);
      setShowEndTimeDrawer(true);
    } else {
      onSlotsSelect([slot]);
    }
  };

  const handleEndTimeSelect = (endSlot: Spot) => {
    if (!selectedSlots[0]) return;
    
    const startSlot = selectedSlots[0];
    const allSlots = getSlotsBetween(startSlot, endSlot);
    onSlotsSelect(allSlots);
    setShowEndTimeDrawer(false);
  };

  const handleEndTimeDrawerClose = () => {
    setShowEndTimeDrawer(false);
    onSlotsSelect(previousSlots);
  };

  const getSlotsBetween = (startSlot: Spot, endSlot: Spot): Spot[] => {
    if (!selectedDate) return [];
    
    const daySlots = availableSlots.find(day => 
      day.date === formatDateToString(selectedDate))?.spots || [];
    
    // Получаем все доступные конечные слоты для выбранного начального слота
    const availableEndSlots = getAvailableEndSlots(startSlot, daySlots);
    
    // Находим выбранный конечный слот среди доступных
    const targetEndSlot = availableEndSlots.find(s => s.start === endSlot.start);
    
    if (!targetEndSlot) return [startSlot]; // Если конечный слот недоступен, возвращаем только начальный
    
    // Возвращаем все слоты до выбранного конечного включительно
    return availableEndSlots.slice(0, availableEndSlots.indexOf(targetEndSlot) + 1);
  };

  const getAvailableEndSlots = (startSlot: Spot, allSlots: Spot[]): Spot[] => {
    if (!selectedDate || !startSlot) return [];
    
    const result: Spot[] = [];
    let currentTime = new Date(`${selectedDate.toDateString()} ${startSlot.start}`);
    
    const startSlotEndTime = new Date(currentTime.getTime() + duration * 60 * 1000);
    result.push({
      ...startSlot,
      end: startSlotEndTime.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
    });
    
    for (let i = 0; i < allSlots.length; i++) {
      const slot = allSlots[i];
      const slotTime = new Date(`${selectedDate.toDateString()} ${slot.start}`);
      
      if (slotTime > currentTime && 
        (slotTime.getTime() - currentTime.getTime()) % (duration * 60 * 1000) === 0) {
        
        const checkTime = currentTime;
        const hasGap = allSlots
          .filter(s => {
            const time = new Date(`${selectedDate.toDateString()} ${s.start}`);
            return time > checkTime && time <= slotTime;
          })
          .some(s => s.quantity === 0);
        
        if (!hasGap) {
          const endTime = new Date(slotTime.getTime() + duration * 60 * 1000);
          result.push({
            ...slot,
            end: endTime.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
          });
          currentTime = slotTime;
        } else {
          break;
        }
      }
    }
    
    return result;
  };

  const renderTimeSlots = () => {
    if (!selectedDate) return null;

    // Показываем выбранное время только если drawer закрыт и есть выбранные слоты
    if (allowMultipleSlots && selectedSlots.length > 0 && !showEndTimeDrawer) {
      const firstSlot = selectedSlots[0];
      const lastSlot = selectedSlots[selectedSlots.length - 1];
      
      return (
        <Box ref={timeSlotsRef}>
          <Typography variant="h6" gutterBottom>
            {isMobile && `Время на ${selectedDate.toLocaleDateString('ru-RU')}`}
            {!isMobile && 'Выбранное время:'}
          </Typography>
          <Box sx={{ p: 2, bgcolor: 'primary.light', borderRadius: 1, color: 'white' }}>
            <Typography variant="h6">
              {`${firstSlot.start} — ${lastSlot.end}`}
            </Typography>
            <Typography>
              {formatDuration((new Date(`2000/01/01 ${lastSlot.end}`).getTime() - 
                new Date(`2000/01/01 ${firstSlot.start}`).getTime()) / (1000 * 60))}
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => onSlotsSelect([])}
              sx={{ mt: 1 }}
            >
              Изменить время
            </Button>
          </Box>
        </Box>
      );
    }

    const daySlots = availableSlots.find(day => day.date === formatDateToString(selectedDate))?.spots || [];
    
    return (
      <Box ref={timeSlotsRef}>
        <Typography variant="h6" gutterBottom>
          {isMobile && `Время на ${selectedDate.toLocaleDateString('ru-RU')}`}
          {!isMobile && 'Доступное время:'}
        </Typography>
        {daySlots.length > 0 ? (
          <Grid container spacing={1}>
            {daySlots.map((slot, index) => (
              <Grid item key={index}>
                <Button
                  size="small"
                  variant={selectedSlots.some(s => s.start === slot.start) && !showEndTimeDrawer ? "contained" : "outlined"}
                  onClick={() => slot.quantity > 0 ? handleSlotSelect(slot) : null}
                  disabled={slot.quantity === 0}
                  sx={{
                    opacity: slot.quantity === 0 ? 0.5 : 1,
                    cursor: slot.quantity === 0 ? 'not-allowed' : 'pointer',
                  }}
                >
                  {slot.start}
                </Button>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ 
            textAlign: 'center', 
            py: 4, 
            bgcolor: 'grey.50', 
            borderRadius: 1 
          }}>
            <Typography variant="h6" gutterBottom>
              Нет доступного времени
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {selectedDate.toLocaleString('ru-RU', { month: 'long', day: 'numeric' })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Пожалуйста, выберите другую дату
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderCalendar = () => (
    <Box position="relative">
      <BookingCalendar
        availableSlots={availableSlots}
        onDateSelect={onDateSelect}
        selectedDate={selectedDate}
        isMobile={isMobile}
        onMonthChange={handleMonthChange}
        currentMonth={currentMonth}
      />
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.7)"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  const renderDesktopLayout = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Typography variant="h5" gutterBottom>Выберите дату</Typography>
        {renderCalendar()}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderTimeSlots()}
      </Grid>
    </Grid>
  );

  const renderMobileLayout = () => (
    <Box>
      <Typography variant="h5" gutterBottom>
        Выберите дату и время
      </Typography>
      {renderCalendar()}
      {selectedDate && (
        <Box sx={{ mt: 3, mb: 2 }}>
          {renderTimeSlots()}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {isMobile ? renderMobileLayout() : renderDesktopLayout()}
      {allowMultipleSlots && selectedSlots.length > 0 && office && (
        <EndTimeDrawer
          open={showEndTimeDrawer}
          onClose={handleEndTimeDrawerClose}
          startSlot={selectedSlots[0]}
          availableEndSlots={getAvailableEndSlots(selectedSlots[0], 
            selectedDate 
              ? availableSlots.find(day => day.date === formatDateToString(selectedDate))?.spots || []
              : []
          )}
          onSelect={handleEndTimeSelect}
          dayPrice={effectiveDayPrice || 0}
          singleSlotPrice={basePrice}
          multipleSlotPrice={multipleSlotPrice}
          minimumSlots={effectiveMinimumSlots}
        />
      )}
    </>
  );
};

export default DateTimeSelector; 