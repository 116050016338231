import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { sendPageView, setUserId, setUserParams } from '../utils/metrika';
import { useAuth } from 'shared-lib';

/**
 * Hook to track page views in Yandex Metrika
 * This hook should be used in the top-level component of your application
 */
const useYandexMetrika = (): void => {
  const location = useLocation();
  const prevPathname = useRef<string>(location.pathname);
  const { user } = useAuth();

  // Set user ID and parameters when authenticated user is available
  useEffect(() => {
    if (user && user.uid) {
      // Set user ID for cross-device tracking
      setUserId(user.uid);
      
      // Set user parameters for better segmentation
      const userParams: Record<string, any> = {
        userId: user.uid,
        isAuthenticated: true
      };
      
      setUserParams(userParams);
    }
  }, [user]);

  useEffect(() => {
    // Only send page view if the pathname has changed
    if (location.pathname !== prevPathname.current) {
      sendPageView(location.pathname + location.search);
      prevPathname.current = location.pathname;
    }
  }, [location]);
};

export default useYandexMetrika; 