import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography, Chip } from '@mui/material';
import { PaymentMethod } from '../../types/types';
import { getStatusText, getStatusColor, getPaymentStatusText } from '../../utils/bookingUtils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import TimerIcon from '@mui/icons-material/Timer';
import CommentIcon from '@mui/icons-material/Comment';
import { formatCompactDateRange } from '../../utils/formatters';
const BookingMainInfo = ({ booking, office, compact = false }) => {
    var _a;
    const formatPrice = (price) => {
        return `${(price / 100).toFixed(0)} ₽`;
    };
    const isPaymentExpiringSoon = () => {
        if (!booking.createdAt ||
            !booking.payment ||
            booking.status === 'CANCELLED' ||
            ['CONFIRMED', 'AUTHORIZED'].includes(booking.payment.status) ||
            booking.payment.method === 'FREE') {
            return false;
        }
        const deadline = new Date(new Date(booking.createdAt).getTime() + 60 * 60 * 1000);
        const now = new Date();
        return now < deadline;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 } }, { children: [_jsx(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: _jsx(Typography, Object.assign({ variant: "body1", sx: {
                                fontWeight: 'bold',
                                color: 'text.primary'
                            } }, { children: (office === null || office === void 0 ? void 0 : office.name) || 'Неизвестный офис' })) })), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', gap: 1 } }, { children: [isPaymentExpiringSoon() && (_jsx(TimerIcon, { sx: { color: 'warning.main' } })), _jsx(Chip, { label: getStatusText(booking.status), color: getStatusColor(booking.status), size: "small" })] }))] })), !compact && (_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', mb: 1 } }, { children: [_jsx(LocationOnIcon, { sx: { mr: 1, color: 'text.secondary', fontSize: 20 } }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: booking.address || 'Адрес не указан' }))] }))), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 } }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(EventIcon, { sx: { mr: 1, color: 'text.secondary', fontSize: 20 } }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: formatCompactDateRange(booking.startTime, booking.endTime, booking.timezone) }))] })), _jsx(Box, Object.assign({ sx: { textAlign: 'right' } }, { children: _jsx(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' } }, { children: _jsx(Typography, Object.assign({ component: "span", variant: "body2", sx: Object.assign({ whiteSpace: 'nowrap' }, (((_a = booking.payment) === null || _a === void 0 ? void 0 : _a.method) === PaymentMethod.FREE && {
                                    textDecoration: 'line-through',
                                    color: 'text.secondary'
                                })) }, { children: booking.totalPrice ? formatPrice(booking.totalPrice) : 'Не указана' })) })) }))] })), _jsxs(Box, Object.assign({ sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    mt: 1
                } }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(PersonIcon, { sx: { mr: 1, color: 'text.secondary', fontSize: 20 } }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: booking.clientName || 'Клиент не указан' }))] })), booking.payment && (_jsx(Typography, Object.assign({ component: "span", variant: "body2", color: booking.payment.method === PaymentMethod.FREE ?
                            'success.main' :
                            booking.payment.status === 'CONFIRMED' ?
                                'success.main' :
                                ['REJECTED', 'CANCELLED', 'REFUNDED'].includes(booking.payment.status) ?
                                    'error.main' :
                                    'text.secondary' }, { children: booking.payment.method === PaymentMethod.FREE ?
                            'Бесплатно' :
                            getPaymentStatusText(booking.payment.status) })))] })), !compact && booking.booking_comment && (_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'flex-start', mt: 1 } }, { children: [_jsx(CommentIcon, { sx: { mr: 1, mt: 0.5, color: 'text.secondary', fontSize: 20 } }), _jsxs(Typography, Object.assign({ variant: "body2" }, { children: [_jsx("strong", { children: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439:" }), " ", booking.booking_comment] }))] })))] }));
};
export default BookingMainInfo;
