import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Button, 
  CircularProgress, 
  Alert,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Location } from 'shared-lib';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useUserLocationStatus, useUserResidencyRequest, useCreateResidencyRequest } from '../../hooks/useLocationQueries';
import OfficeList from '../OfficeList';
import { useNavigate } from 'react-router-dom';

interface LocationDetailsProps {
  location: Location;
  onBack: () => void;
  onViewOffices: (location: Location) => void;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({ 
  location, 
  onBack,
  onViewOffices
}) => {
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  const [reason, setReason] = useState('');
  const navigate = useNavigate();
  
  const userStatus = useUserLocationStatus(location.id);
  const residencyRequest = useUserResidencyRequest(location.id);
  const createResidencyRequest = useCreateResidencyRequest();
  
  const handleRequestSubmit = async () => {
    try {
      await createResidencyRequest.mutateAsync({
        locationId: location.id,
        reason
      });
      setRequestDialogOpen(false);
      setReason('');
    } catch (error) {
      console.error('Error creating residency request:', error);
    }
  };

  const handleSelectOffice = (office: any) => {
    navigate('/');
    // Здесь должен быть код для открытия диалогового окна бронирования
  };
  
  const renderStatusInfo = () => {
    // Если запрос на загрузку статуса и запроса на резидентство все еще выполняется
    if (userStatus.isLoading || residencyRequest.isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          <Typography variant="body2">Проверка статуса...</Typography>
        </Box>
      );
    }
    
    // Если произошла ошибка при загрузке
    if (userStatus.error || residencyRequest.error) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          Не удалось загрузить информацию о статусе пользователя
        </Alert>
      );
    }
    
    // Если пользователь заблокирован
    if (userStatus.data?.role === 'blocked') {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          Вы заблокированы в этой локации и не можете бронировать офисы.
        </Alert>
      );
    }
    
    // Если пользователь админ
    if (userStatus.data?.role === 'admin') {
      return (
        <Alert severity="success" sx={{ mt: 2 }}>
          Вы являетесь администратором этой локации и можете бронировать офисы бесплатно.
        </Alert>
      );
    }
    
    // Если пользователь резидент
    if (userStatus.data?.role === 'resident') {
      return (
        <Alert severity="success" sx={{ mt: 2 }}>
          Вы являетесь резидентом этой локации и можете бронировать офисы.
        </Alert>
      );
    }
    
    // Если есть запрос на резидентство в статусе pending
    if (residencyRequest.data?.status === 'pending') {
      return (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Ваш запрос на резидентство находится на рассмотрении.
        </Alert>
      );
    }
    
    // Если есть запрос на резидентство в статусе rejected
    if (residencyRequest.data?.status === 'rejected') {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Ваш запрос на резидентство был отклонен.
          </Typography>
          {residencyRequest.data.rejectionReason && (
            <Typography variant="body2">
              <strong>Причина отказа:</strong> {residencyRequest.data.rejectionReason}
            </Typography>
          )}
        </Alert>
      );
    }
    
    // По умолчанию - пользователь не резидент и нет активных запросов
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        Вы не являетесь резидентом этой локации. Вы можете запросить резидентство для упрощения бронирования.
      </Alert>
    );
  };

  const canRequestResidency = () => {
    // Нельзя запросить резидентство, если пользователь уже резидент, админ, привилегированный или заблокирован
    if (userStatus.data?.role) {
      return false;
    }
    
    // Нельзя запросить резидентство, если уже есть запрос в статусе pending
    if (residencyRequest.data?.status === 'pending') {
      return false;
    }
    
    return true;
  };
  
  const canViewOffices = () => {
    // Нельзя просматривать офисы, если пользователь заблокирован
    return userStatus.data?.role !== 'blocked';
  };
  
  return (
    <>
      <Box sx={{ mt: 4, mb: 4, px: 3 }}>
        <Button 
          variant="outlined" 
          onClick={onBack} 
          sx={{ mb: 2 }}
        >
          Назад к списку локаций
        </Button>
        
        <Paper sx={{ p: 3, mb: 3 }}>
          {location.images && location.images.length > 0 && (
            <Box 
              component="img" 
              src={location.images[0]} 
              alt={location.name}
              sx={{ 
                width: '100%', 
                height: 300, 
                objectFit: 'cover', 
                borderRadius: 1,
                mb: 3 
              }}
            />
          )}
          
          <Typography variant="h4" component="h1" gutterBottom>
            {location.name}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body1" color="text.secondary">
              {location.address}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body1" color="text.secondary">
              Часовой пояс: {location.timezone}
            </Typography>
          </Box>
          
          <Typography variant="body1" sx={{ mb: 3 }}>
            {location.description || 'Нет описания'}
          </Typography>
          
          <Divider sx={{ mb: 3 }} />
          
          <Typography variant="h6" gutterBottom>
            Ваш статус в локации
          </Typography>
          
          {renderStatusInfo()}
          
          <Box sx={{ mt: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            {canRequestResidency() && (
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => setRequestDialogOpen(true)}
              >
                Запросить резидентство
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
      
      {/* Диалог запроса на резидентство */}
      <Dialog open={requestDialogOpen} onClose={() => setRequestDialogOpen(false)}>
        <DialogTitle>Запрос резидентства</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Расскажите, пожалуйста, для каких целей вы планируете использовать эту локацию. 
            Это поможет администратору принять решение о вашем запросе.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Цель использования"
            fullWidth
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRequestDialogOpen(false)}>Отмена</Button>
          <Button 
            onClick={handleRequestSubmit}
            disabled={!reason.trim() || createResidencyRequest.isPending}
          >
            {createResidencyRequest.isPending ? 'Отправка...' : 'Отправить запрос'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Офисы локации */}
      {canViewOffices() && (
        <OfficeList 
          locationId={location.id}
          offices={[]}
          onSelectOffice={handleSelectOffice}
          isLoading={false}
        />
      )}
    </>
  );
};

export default LocationDetails; 