import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Button, Divider, TextField, 
  CircularProgress, FormControlLabel, Switch, Alert 
} from '@mui/material';
import { Client, Office, Spot, useAuth } from 'shared-lib';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import ClientSelector from '../ClientSelector';
import { PriceCalculationService } from '../../services/PriceCalculationService';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

interface BookingConfirmationStepProps {
  selectedDate: Date;
  selectedSlots: Spot[];
  office: Office;
  onBack: () => void;
  onConfirm: (comment: string, client: Client | null) => Promise<void>;
}

const BookingConfirmationStep: React.FC<BookingConfirmationStepProps> = ({
  selectedDate,
  selectedSlots,
  office,
  onBack,
  onConfirm
}) => {
  const { user } = useAuth();
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [bookWithoutClient, setBookWithoutClient] = useState(false);
  const [error, setError] = useState('');
  const [residentDiscount, setResidentDiscount] = useState<{ discount: number; isFree: boolean } | null>(null);

  const isAdmin = office?.admins?.some(adminRef => adminRef.id === user?.uid);

  useEffect(() => {
    const fetchResidentDiscount = async () => {
      if (!user || !office.locationId) return;

      try {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'locationUsers', `${user.uid}_${office.locationId}`));
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData?.officeDiscounts?.[office.id]) {
            setResidentDiscount(userData.officeDiscounts[office.id]);
          }
        }
      } catch (error) {
        console.error('Error fetching resident discount:', error);
      }
    };

    fetchResidentDiscount();
  }, [user, office.id, office.locationId]);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('ru-RU', { 
      weekday: 'long', 
      day: 'numeric', 
      month: 'long' 
    });
  };

  const calculateTotalPrice = () => {
    if (isAdmin) {
      return 0;
    }

    // Сначала считаем базовую цену
    let totalPrice = PriceCalculationService.calculateTotalPriceForSlots(
      selectedSlots,
      office.price.day_price || null,
      office.bookingSettings?.minimum_slots || 1,
      office.price.base_price,
      office.price.multiple_slot_price || office.price.base_price
    );

    // Потом применяем скидку если она есть
    if (residentDiscount) {
      if (residentDiscount.isFree) {
        return 0;
      }
      totalPrice = Math.round(totalPrice * (1 - residentDiscount.discount / 100));
    }

    return totalPrice;
  };

  const formatPrice = (price: number) => `${(price / 100).toFixed(0)} ₽`;

  const handleConfirm = async () => {
    if (!bookWithoutClient && !selectedClient) {
      setError('Выберите клиента или отметьте "Бронировать без клиента"');
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      await onConfirm(comment, selectedClient);
    } catch (error) {
      console.error('Error confirming booking:', error);
      setError('Ошибка при подтверждении бронирования');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Подтверждение бронирования
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography>
            Дата: {formatDate(selectedDate)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon sx={{ mr: 1 }} />
          <Typography>
            Время: {selectedSlots[0].start} - {selectedSlots[selectedSlots.length - 1].end}
          </Typography>
        </Box>
      </Box>

      {!bookWithoutClient && (
        <ClientSelector
          onClientSelect={setSelectedClient}
          selectedClient={selectedClient}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={bookWithoutClient}
            onChange={(e) => {
              setBookWithoutClient(e.target.checked);
              if (e.target.checked) {
                setSelectedClient(null);
              }
            }}
          />
        }
        label="Бронировать без клиента"
      />

      <TextField
        fullWidth
        label="Комментарий"
        multiline
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      <Divider sx={{ my: 2 }} />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography>Всего</Typography>
        <Typography fontWeight="bold">
          {isAdmin ? 
            'Бесплатно (администратор)' : 
            formatPrice(calculateTotalPrice())
          }
        </Typography>
      </Box>

      {residentDiscount && (
        <Typography variant="body2" color="text.secondary">
          Применена скидка резидента {residentDiscount.discount}%
        </Typography>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onBack}>
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={isLoading || (!bookWithoutClient && !selectedClient)}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Подтвердить'}
        </Button>
      </Box>

      <Typography variant="caption" sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
        Записываясь, вы даете согласие на обработку своих персональных данных.
      </Typography>
    </Box>
  );
};

export default BookingConfirmationStep; 