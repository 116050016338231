import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Container, CircularProgress, Box, TextField, Button, Paper, Grid, useMediaQuery, useTheme, AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar, Divider, Stepper, Step, StepLabel } from '@mui/material';
import { Spot, useAuth } from 'shared-lib';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { formatDateToString } from '../utils/dateUtils';
import { usePublicServiceInfo, useAvailableSlotsForUser, useCreateBooking } from '../hooks/useBookingQueries';
import DateTimeSelector from './DateTimeSelector';
import useLoadingTracker from '../hooks/useLoadingTracker';

interface ClientData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const BookingPage: React.FC = () => {
  const { userId = '', serviceId = '' } = useParams<{ userId: string; serviceId: string }>();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState<ClientData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlots, setSelectedSlots] = useState<Spot[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<{
    date: string;
    time: string;
    serviceName: string;
    providerName: string;
  } | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  // Queries
  const {
    data: serviceInfo,
    isLoading: isServiceInfoLoading,
    error: serviceInfoError
  } = usePublicServiceInfo(userId, serviceId);

  const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

  const {
    data: availableSlots = [],
    isLoading: isSlotsLoading,
    error: slotsError,
    isFetching: isSlotsFetching
  } = useAvailableSlotsForUser(serviceId, userId, startOfMonth, endOfMonth);

  // Отслеживаем состояние загрузки для Вебвизора
  useLoadingTracker(isServiceInfoLoading, 'BookingPage-ServiceInfo');
  useLoadingTracker(isSlotsLoading || isSlotsFetching, 'BookingPage-Slots');

  // Mutations
  const createBookingMutation = useCreateBooking();

  const isBooking = createBookingMutation.isPending;

  useEffect(() => {
    const savedClientData = localStorage.getItem('clientData');
    if (savedClientData) {
      setClientData(JSON.parse(savedClientData));
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setClientData(prevData => {
      const newData = { ...prevData, [name]: value };
      // Сохранение данных в локальное хранилище при каждом изменении
      localStorage.setItem('clientData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleDateSelect = useCallback((date: Date) => {
    setSelectedDate(date);
    setSelectedSlots([]);
  }, []);

  const handleSlotsSelect = useCallback((slots: Spot[]) => {
    setSelectedSlots(slots);
  }, []);

  const handleNext = () => {
    if (isMobile) {
      if (activeStep === 0) {
        setActiveStep(1);
        return;
      }
      if ((activeStep === 1 && !selectedDate) || selectedSlots.length === 0) {
        alert('Пожалуйста, выберите дату и время');
        return;
      }
      if (activeStep === 2 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone)) {
        alert('Пожалуйста, заполните все поля');
        return;
      }
    } else {
      if (activeStep === 0 && (!selectedDate || selectedSlots.length === 0)) {
        alert('Пожалуйста, выберите дату и время');
        return;
      }
      if (activeStep === 1 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone)) {
        alert('Пожалуйста, заполните все поля');
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    if (!selectedDate || selectedSlots.length === 0 || !serviceInfo || !serviceInfo.provider) {
      console.error("Не выбрана дата, время или отсутствует информация о сервисе или провайдере");
      return;
    }

    const selectedSlotData = {
      date: formatDateToString(selectedDate),
      spot: selectedSlots[0]
    };

    try {
      await createBookingMutation.mutateAsync({
        locationId: serviceInfo.location?.id || '',
        selectedDaySpots: {
          date: selectedSlotData.date,
          spots: [selectedSlotData.spot]
        },
        totalPrice: selectedSlotData.spot.prices,
        userId,
        comment: '',
        clientData: {
          name: `${clientData.firstName} ${clientData.lastName}`,
          phone: clientData.phone,
          email: clientData.email,
        },
        serviceId
      });

      setBookingSuccess(true);
      setBookingDetails({
        date: selectedDate.toLocaleDateString('ru-RU'),
        time: `${selectedSlots[0].start} - ${selectedSlots[0].end}`,
        serviceName: serviceInfo.name,
        providerName: `${serviceInfo.provider.firstName} ${serviceInfo.provider.lastName}`,
      });
    } catch (error) {
      console.error("Ошибка при создании бронирования:", error);
      // Handle error appropriately
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    handleMenuClose();
  };

  const handleEditService = () => {
    if (serviceInfo) {
      navigate(`/edit-service/${serviceInfo.id}`);
    }
    handleMenuClose();
  };

  const handleMonthChange = useCallback((date: Date) => {
    if (date.getMonth() !== currentMonth.getMonth() || date.getFullYear() !== currentMonth.getFullYear()) {
      setCurrentMonth(date);
    }
  }, [currentMonth]);

  const renderSpecialistInfo = () => (
    <Box mb={4}>
      {serviceInfo?.provider && (
        <>
          <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
            {serviceInfo.provider.firstName.charAt(0)}
            {serviceInfo.provider.lastName.charAt(0)}
          </Avatar>
          <Typography variant="h5" gutterBottom>
            {serviceInfo.provider.firstName} {serviceInfo.provider.lastName}
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {serviceInfo.name}
          </Typography>
          {serviceInfo.provider.email && (
            <Typography variant="body2">
              <EmailIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              {serviceInfo.provider.email}
            </Typography>
          )}
          {serviceInfo.provider.phone && (
            <Typography variant="body2">
              <PhoneIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              {serviceInfo.provider.phone}
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  const renderServiceDetails = () => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>О услуге</Typography>
      {serviceInfo?.description && (
        <Typography variant="body2" paragraph>{serviceInfo.description}</Typography>
      )}
      <Box mb={2}>
        <Typography variant="body2">
          <AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
          Длительность: {serviceInfo?.duration} мин.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">
          <AttachMoneyIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
          Стоимость: {serviceInfo?.price} руб.
        </Typography>
      </Box>
      {serviceInfo?.location && (
        <Box mb={2}>
          <Typography variant="body2">
            <LocationOnIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
            Адрес: {serviceInfo.location.address}
          </Typography>
          {serviceInfo.location.instructions && (
            <Typography variant="body2">
              Инструкции: {serviceInfo.location.instructions}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );

  const renderDesktopLayout = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        {renderSpecialistInfo()}
        {renderServiceDetails()}
      </Grid>
      <Grid item xs={12} md={9}>
        <DateTimeSelector
          availableSlots={availableSlots}
          selectedDate={selectedDate}
          selectedSlots={selectedSlots}
          onDateSelect={handleDateSelect}
          onSlotsSelect={handleSlotsSelect}
          isMobile={isMobile}
          onMonthChange={handleMonthChange}
          currentMonth={currentMonth}
          isLoading={isSlotsFetching}
          allowMultipleSlots={false}
        />
      </Grid>
    </Grid>
  );

  const renderMobileLayout = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            {renderSpecialistInfo()}
            <Divider sx={{ my: 2 }} />
            {renderServiceDetails()}
            <Button
              variant="contained"
              fullWidth
              onClick={handleNext}
              sx={{ mt: 2 }}
            >
              Перейти к выбору даты
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <DateTimeSelector
              availableSlots={availableSlots}
              selectedDate={selectedDate}
              selectedSlots={selectedSlots}
              onDateSelect={handleDateSelect}
              onSlotsSelect={handleSlotsSelect}
              isMobile={isMobile}
              onMonthChange={handleMonthChange}
              currentMonth={currentMonth}
              isLoading={isSlotsFetching}
              allowMultipleSlots={false}
            />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!selectedDate || selectedSlots.length === 0}
              >
                Далее
              </Button>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            {renderClientDataForm()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone}
              >
                Далее
              </Button>
            </Box>
          </>
        );
      case 3:
        return (
          <>
            {renderConfirmation()}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>
                Назад
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Забронировать
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  const renderClientDataForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Имя" name="firstName" value={clientData.firstName} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Фамилия" name="lastName" value={clientData.lastName} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Email" name="email" type="email" value={clientData.email} onChange={handleInputChange} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Телефон" name="phone" value={clientData.phone} onChange={handleInputChange} />
      </Grid>
    </Grid>
  );

  const renderConfirmation = () => (
    <Box>
      <Typography variant="subtitle1" gutterBottom>Подтверждение бронирования</Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}><EventIcon fontSize="small" /> Дата:</Grid>
        <Grid item xs={6}>{selectedDate?.toLocaleDateString('ru-RU')}</Grid>
        <Grid item xs={6}><AccessTimeIcon fontSize="small" /> Время:</Grid>
        <Grid item xs={6}>{`${selectedSlots[0].start} - ${selectedSlots[0].end}`}</Grid>
        <Grid item xs={6}><PersonIcon fontSize="small" /> Имя:</Grid>
        <Grid item xs={6}>{`${clientData.firstName} ${clientData.lastName}`}</Grid>
        <Grid item xs={6}><EmailIcon fontSize="small" /> Email:</Grid>
        <Grid item xs={6}>{clientData.email}</Grid>
        <Grid item xs={6}><PhoneIcon fontSize="small" /> Телефон:</Grid>
        <Grid item xs={6}>{clientData.phone}</Grid>
      </Grid>
      {isBooking && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  const steps = isMobile 
    ? ['', '', '', '']
    : ['Выбор даты и времени', 'Ввод данных', 'Подтверждение'];

  const renderStepContent = (step: number) => {
    if (isMobile) {
      return renderMobileLayout();
    } else {
      switch (step) {
        case 0:
          return renderDesktopLayout();
        case 1:
          return renderClientDataForm();
        case 2:
          return renderConfirmation();
        default:
          return null;
      }
    }
  };

  const renderNavigation = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button 
        disabled={activeStep === 0 || isBooking} 
        onClick={handleBack}
      >
        Назад
      </Button>
      <Button
        variant="contained"
        onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
        disabled={
          isBooking ||
          (activeStep === 0 && (!selectedDate || selectedSlots.length === 0)) ||
          (activeStep === 1 && (!clientData.firstName || !clientData.lastName || !clientData.email || !clientData.phone))
        }
      >
        {activeStep === steps.length - 1 ? (isBooking ? 'Бронирование...' : 'Забронировать') : 'Далее'}
      </Button>
    </Box>
  );

  const renderSuccessMessage = () => (
    <Box textAlign="center">
      <Typography variant="h5" gutterBottom>
        Бронирование успешно создано!
      </Typography>
      <Typography variant="body1" paragraph>
        Вы забронировали {bookingDetails?.serviceName} 
        {bookingDetails?.providerName && ` у ${bookingDetails.providerName}`} 
        на {bookingDetails?.date} с {bookingDetails?.time}.
      </Typography>
      <Typography variant="body1" paragraph>
        {bookingDetails?.providerName && `Рекомендуем связаться с ${bookingDetails.providerName} для подтверждения деталей. `}
        Дальнейшее управление бронированием осуществляется через вашего специалиста.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          setBookingSuccess(false);
          setActiveStep(0);
          setSelectedDate(null);
          setSelectedSlots([]);
        }}
      >
        Создать новое бронирование
      </Button>
    </Box>
  );

  if (isServiceInfoLoading || isSlotsLoading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (serviceInfoError || slotsError || !userId || !serviceId || !serviceInfo) {
    return (
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Ошибка
        </Typography>
        <Typography>
          {serviceInfoError instanceof Error ? serviceInfoError.message : 
           slotsError instanceof Error ? slotsError.message : 
           'Неверные параметры URL или услуга не найдена'}
        </Typography>
      </Container>
    );
  }

  if (bookingSuccess) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          {renderSuccessMessage()}
        </Paper>
      </Container>
    );
  }

  return (
    <>
      {user && (
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" component={Link} to="/">
              <HomeIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Бронирование
            </Typography>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditService}>
                <EditIcon fontSize="small" sx={{ mr: 1 }} />
                Редатировать сервис
              </MenuItem>
              <MenuItem onClick={handleCopyLink}>
                <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
                Копировать ссылку
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                {isMobile ? (
                  <StepLabel>{label}</StepLabel>
                ) : (
                  <StepLabel>{label}</StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
          {renderStepContent(activeStep)}
          {!isMobile && renderNavigation()}
        </Paper>
      </Container>
    </>
  );
};

export default React.memo(BookingPage);
