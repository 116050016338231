import React, { useState, useEffect } from 'react';
import { 
  SwipeableDrawer,
  Box,
  Typography,
  Button,
  TextField,
  ImageList,
  ImageListItem,
  CircularProgress,
  IconButton,
  Alert
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';

interface BookingCheckInDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { photos: string[], comment: string }) => Promise<void>;
  title: string;
  description: string;
  submitButtonText: string;
  isLoading?: boolean;
  bookingId: string;
  type: 'start' | 'completion';
}

const BookingCheckInDrawer: React.FC<BookingCheckInDrawerProps> = ({
  open,
  onClose,
  onSubmit,
  title,
  description,
  submitButtonText,
  isLoading = false,
  bookingId,
  type
}) => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const loadExistingPhotos = async () => {
      if (!open) return;
      
      try {
        const storage = getStorage();
        const photosRef = ref(storage, `bookings/${bookingId}/${type}`);
        const result = await listAll(photosRef);
        
        const urls = await Promise.all(
          result.items.map(item => getDownloadURL(item))
        );
        
        setPhotos(urls);
      } catch (error) {
        console.error('Error loading existing photos:', error);
      }
    };

    loadExistingPhotos();
  }, [open, bookingId, type]);

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) return;

    setIsUploading(true);
    try {
      const storage = getStorage();
      
      const uploadedUrls = await Promise.all(
        Array.from(files).map(async (file, index) => {
          const timestamp = Date.now();
          const path = `bookings/${bookingId}/${type}/photo_${timestamp}_${index}.jpg`;
          const photoRef = ref(storage, path);
          
          await uploadBytes(photoRef, file, {
            contentType: 'image/jpeg',
          });
          return getDownloadURL(photoRef);
        })
      );

      setPhotos(prev => [...prev, ...uploadedUrls]);
    } catch (error) {
      console.error('Error uploading photos:', error);
      setError('Ошибка при загрузке фотографий');
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async () => {
    if (photos.length === 0) {
      setError('Пожалуйста, добавьте хотя бы одно фото');
      return;
    }

    try {
      await onSubmit({ photos, comment });
      handleClose();
    } catch (error) {
      console.error('Error submitting booking check-in/out:', error);
      setError('Произошла ошибка при отправке данных');
    }
  };

  const handleClose = () => {
    setPhotos([]);
    setComment('');
    setError('');
    onClose();
  };

  const handleDeletePhoto = async (photoUrl: string) => {
    try {
      const storage = getStorage();
      const photoRef = ref(storage, photoUrl);
      await deleteObject(photoRef);
      
      setPhotos(prev => prev.filter(url => url !== photoUrl));
    } catch (error) {
      console.error('Error deleting photo:', error);
      setError('Ошибка при удалении фото');
    }
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={() => {}}
      swipeAreaWidth={0}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          position: 'absolute',
          bottom: 0,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="photo-upload"
            multiple
            type="file"
            onChange={handlePhotoUpload}
            disabled={isUploading}
          />
          <label htmlFor="photo-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<PhotoCamera />}
              disabled={isUploading}
            >
              Добавить фото
            </Button>
          </label>
          {isUploading && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                Загрузка фотографий...
              </Typography>
            </Box>
          )}
        </Box>

        {photos.length > 0 && (
          <ImageList sx={{ mt: 2 }} cols={3} rowHeight={164}>
            {photos.map((photo, index) => (
              <ImageListItem key={index} sx={{ position: 'relative' }}>
                <img src={photo} alt={`Фото ${index + 1}`} loading="lazy" />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' }
                  }}
                  onClick={() => handleDeletePhoto(photo)}
                >
                  <DeleteIcon sx={{ color: 'white' }} />
                </IconButton>
              </ImageListItem>
            ))}
          </ImageList>
        )}

        <TextField
          fullWidth
          multiline
          rows={3}
          label="Комментарий"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={isLoading}
          sx={{ mt: 2, mb: 2 }}
        />

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          justifyContent: 'flex-end',
          flexDirection: { xs: 'column', sm: 'row' }
        }}>
          <Button 
            onClick={handleClose}
            disabled={isUploading || isLoading}
            fullWidth={true}
            sx={{ order: { xs: 2, sm: 1 } }}
          >
            Отмена
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isUploading || isLoading}
            fullWidth={true}
            sx={{ order: { xs: 1, sm: 2 } }}
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            {submitButtonText}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default BookingCheckInDrawer;
